import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import Section from "components/Section";
import { useHeader } from "hooks";

import {
    Heading,
    Introduction,
    Container,
    Col,
    StyledForm,
} from "./NewsletterSignup.styled";
import NewsletterSignupProps from "./NewsletterSignupProps";

const NewsletterSignup = ({
    identifier,
    anchorName,
    theme = "lightgray",
    contentTheme = "black",
    actionTheme = "cyan",
    displayOption = "Right",
    form,
}: NewsletterSignupProps): ReactElement => {
    const headerState = useHeader();
    return (
        <Section
            theme={theme}
            key={identifier}
            id={anchorName}
            alignItems="center"
            editPropertyName="FormLink"
        >
            <ThemeProvider
                theme={{
                    theme: theme,
                    contentTheme: contentTheme,
                    actionTheme: actionTheme,
                    displayOption: displayOption,
                }}
            >
                <Container key={identifier || `FormSectionContent`}>
                    {form?.heading && (
                        <Heading
                            $headerVisible={headerState.visible}
                            dangerouslySetInnerHTML={{
                                __html: form.heading,
                            }}
                        />
                    )}
                    {form?.formElements && (
                        <Col>
                            {form?.introduction && (
                                <Introduction>{form.introduction}</Introduction>
                            )}
                            <StyledForm
                                id={form.formId}
                                items={form.formElements}
                                action={form.postUrl}
                                spamTrapAction={form.spamTrapUrl}
                                identifier={form.identifier}
                                size={form.size}
                                theme={theme}
                                actionTheme={actionTheme}
                                gtmFormStartedEvent="newsletter_started"
                                gtmFormSubmittedEvent="newsletter_signup"
                                gtmFormType="newsletter"
                            />
                        </Col>
                    )}
                </Container>
            </ThemeProvider>
        </Section>
    );
};

export default React.memo(NewsletterSignup);
