import { styled } from "styled-components";
import theme from "styled-theming";

import Form from "components/Form";
import { Container as GridContainer } from "style/components/Page";
import { BodyL, styleHeadingXL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { topPadOffset } from "style/headerOffsets";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)<ContainerProps>`
    ${columnGap}
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    position: relative;

    figure {
        z-index: 1;
    }

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
    }
`;

export const Heading = styled.h2.attrs<{
    $headerVisible: boolean;
}>(() => ({}))`
    ${styleHeadingXL};
    align-self: flex-start;
    grid-column: ${theme("displayOption", {
        Left: "col-start 1 / span 3",
        Right: "col-start 2 / span 3",
    })};
    margin-bottom: 2.625rem;
    margin-top: 0;
    text-align: ${theme("displayOption", {
        Left: "left",
        Right: "right",
    })};

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 1 / span 8",
            Right: "col-start 5 / span 8",
        })};
        margin-bottom: 3.5rem;
    }

    ${MQ.FROM_L} {
        display: block;
        grid-column: ${theme("displayOption", {
            Left: "col-start 1 / span 6",
            Right: "col-start 7 / span 6",
        })};
        margin-bottom: 1.75rem;
        position: sticky;
        top: 3.5rem;
        ${topPadOffset}
        transition: top 0.2s ease-in-out;
    }

    ${MQ.FROM_XL} {
        top: 4.375rem;
        ${topPadOffset}
    }
`;

export const Col = styled.div`
    grid-column: col-start 1 / span 4;
    grid-row: 2;
    z-index: 2;

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 5 / span 8",
            Right: "col-start 1 / span 8",
        })};
    }

    ${MQ.FROM_L} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 7 / span 5",
            Right: "col-start 2 / span 5",
        })};
        grid-row: 1;
        padding-top: 3.9375rem;
    }

    ${MQ.FROM_XL} {
        padding-top: 5.25rem;
    }
`;

export const Introduction = styled(BodyL)`
    margin-bottom: 2.1875rem;
    z-index: 2;

    ${MQ.FROM_M} {
        margin-bottom: 4.375rem;
    }
`;

export const StyledForm = styled(Form)`
    justify-content: flex-start;

    && {
        margin-top: 0;
    }
`;

interface ContainerProps {
    readonly hasImage?: boolean;
}
